import { useCallback } from 'react';

import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import EuroPrice from 'components/EuroPrice';
import SelectableTableRow, {
  SelectableTableRowProps,
} from 'components/SelectableTableRow';

import { formatDate, formatDateTime, truncate } from 'lib';
import { useDialog } from 'components/Dialogs';
import { AdminSessionAbsenceListItem, ClubAbsenceIndexParams } from 'schema';

type Props = SelectableTableRowProps & {
  absence: AdminSessionAbsenceListItem;
  params: ClubAbsenceIndexParams | null;
};

const AbsencesTableRow = ({ absence, ...props }: Props) => {
  const [, { open }] = useDialog('sessionAbsence');

  const {
    id,
    createdAt,
    start,
    end,
    registration,
    comment,
    adminComment,
    discount,
    approvedDiscount,
    status,
    duration,
  } = absence;

  const { attendant } = registration;

  const handleEditClick = useCallback(() => {
    if (status === 'submitted' || status === 'approved') {
      open({ absence });
    }
  }, [absence, open, status]);

  return (
    <SelectableTableRow
      hover
      sx={{ cursor: 'pointer' }}
      onClick={handleEditClick}
      {...props}>
      <TableCell>{id}</TableCell>
      <TableCell>{formatDateTime(createdAt)}</TableCell>

      <TableCell align="center">
        <Box>{formatDate(start)}</Box>
        <Box>{formatDate(end)}</Box>
      </TableCell>

      <TableCell align="center">{duration}</TableCell>
      <TableCell>{attendant.fullName}</TableCell>
      <TableCell>{attendant.guardian.fullName}</TableCell>
      <TableCell>{attendant.guardian.phone}</TableCell>

      <TableCell>
        <Tooltip title={comment}>
          <span>{truncate(comment, 25)}</span>
        </Tooltip>
      </TableCell>

      <TableCell>
        {adminComment ? (
          <Tooltip title={adminComment}>
            <span>{truncate(adminComment, 25)}</span>
          </Tooltip>
        ) : (
          '--'
        )}
      </TableCell>

      <TableCell align="center">
        {discount ? <EuroPrice>{discount}</EuroPrice> : '--'}
      </TableCell>

      <TableCell align="center">
        {approvedDiscount && <EuroPrice>{approvedDiscount}</EuroPrice>}
      </TableCell>
    </SelectableTableRow>
  );
};

export default AbsencesTableRow;
