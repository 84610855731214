import { useCallback } from 'react';

import ReplayIcon from '@mui/icons-material/Replay';
import SendIcon from '@mui/icons-material/Send';
import UploadIcon from '@mui/icons-material/Upload';
import DiscountIcon from '@mui/icons-material/Discount';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import MenuButton from 'components/MenuButton';
import ConfirmableMenuItem from 'components/ConfirmableMenuItem';

import {
  useBulkReSend,
  useBulkSend,
  useResetAccountingSync,
} from 'features/Invoice';
import { useDialog } from 'components/Dialogs';
import { useSelectable } from 'components/MultiSelect';

const InvoicesActionsMenu = () => {
  const [, { open }] = useDialog('setInvoicesDiscount');
  const { selected, hasSelected } = useSelectable();
  const resetSync = useResetAccountingSync();
  const bulkSend = useBulkSend();
  const bulkReSend = useBulkReSend();

  const handleSetDiscountClick = useCallback(() => {
    open({ ids: selected() });
  }, [open, selected]);

  const handleAccountingSync = useCallback(() => {
    resetSync.mutate({ ids: selected() });
  }, [resetSync, selected]);

  const handleBulkSend = useCallback(() => {
    bulkSend.mutate({ ids: selected() });
  }, [bulkSend, selected]);

  const handleBulkReSend = useCallback(() => {
    bulkReSend.mutate({ ids: selected() });
  }, [bulkReSend, selected]);

  return (
    <MenuButton
      renderActivator={open => (
        <Button
          variant="outlined"
          disabled={!hasSelected}
          onClick={open}
          size="small">
          Veiksmai
        </Button>
      )}>
      <MenuItem onClick={handleSetDiscountClick}>
        <ListItemIcon>
          <DiscountIcon />
        </ListItemIcon>

        <ListItemText>Pridėti nuolaidą...</ListItemText>
      </MenuItem>

      <ConfirmableMenuItem
        text="Ar tikrai norite siųsti pažymėtas sąskaitas į buhalteriją?"
        disabled={resetSync.isPending}
        onConfirm={handleAccountingSync}>
        <ListItemIcon>
          {resetSync.isPending ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <UploadIcon />
          )}
        </ListItemIcon>

        <ListItemText>Siųsti į buhalteriją</ListItemText>
      </ConfirmableMenuItem>

      <ConfirmableMenuItem
        text="Ar tikrai norite siųsti pažymėtas sąskaitas klientams?"
        disabled={bulkSend.isPending}
        onConfirm={handleBulkSend}>
        <ListItemIcon>
          {bulkSend.isPending ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <SendIcon />
          )}
        </ListItemIcon>

        <ListItemText>Siųsti sąskaitą klientams</ListItemText>
      </ConfirmableMenuItem>

      <ConfirmableMenuItem
        text="Ar tikrai norite siųsti priminimus pažymėtom sąskaitom?"
        disabled={bulkReSend.isPending}
        onConfirm={handleBulkReSend}>
        <ListItemIcon>
          {bulkReSend.isPending ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            <ReplayIcon />
          )}
        </ListItemIcon>

        <ListItemText>Siųsti priminimus</ListItemText>
      </ConfirmableMenuItem>
    </MenuButton>
  );
};

export default InvoicesActionsMenu;
