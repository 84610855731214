import {
  ChangeEvent,
  ReactNode,
  MouseEvent,
  useCallback,
  useEffect,
} from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import MuiTable, { TableProps as MuiTableProps } from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination, {
  LabelDisplayedRowsArgs,
} from '@mui/material/TablePagination';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import TableEmpty from 'components/TableEmpty';

import { usePagination } from 'components/Pagination';
import { useSelectable } from 'components/MultiSelect';

type Props = MuiTableProps & {
  head?: ReactNode;
  footer?: ReactNode;
  noRecordsText?: ReactNode;
  noRecordsIcon?: ReactNode;
  children?: ReactNode;
  tabs?: ReactNode;
  actions?: ReactNode;
  count?: number;
  loading?: boolean;
  selecting?: boolean;
  paginated?: boolean; // TODO: eventually all tables will be paginated and this will be able to be removed
  bulkSelect?: boolean;
  onSelectAll?: () => void;
};

export type TableProps = Omit<Props, 'head'>;

const displayedRows = ({ from, to, count }: LabelDisplayedRowsArgs) =>
  `${from}–${to} iš ${count}`;

const Table = (props: Props) => {
  const {
    children,
    head,
    footer,
    sx = [],
    noRecordsText,
    noRecordsIcon,
    tabs,
    actions,
    count,
    loading,
    selecting,
    paginated = false,
    bulkSelect = false,
    onSelectAll,
    ...rest
  } = props;

  const hasRows = Array.isArray(children) && children.length > 0;
  const [{ page, size }, { setPage, setSize }] = usePagination();

  const { selectedQuantity, showSelectAllPrompt, setTotalCount } =
    useSelectable();

  const handlePageChange = useCallback(
    (_: any, page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleRowPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSize(Number(event.target.value));
    },
    [setSize]
  );

  const selected = selectedQuantity();

  const handleSelectAll = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      onSelectAll && onSelectAll();
    },
    [onSelectAll]
  );

  useEffect(() => {
    if (count) {
      setTotalCount(count);
    }
  }, [count, setTotalCount]);

  return (
    <Paper sx={sx}>
      {tabs && (
        <>
          {tabs} <Divider />
        </>
      )}

      {loading && <LinearProgress sx={{ width: '100%', height: '1px' }} />}

      <TableContainer sx={{ overflowX: 'visible' }}>
        {(actions || paginated) && (
          <Box>
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{
                  flexGrow: 1,
                  pl: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}>
                {bulkSelect && (
                  <Box sx={{ mr: 1 }}>Su {selected} pažymėtais:</Box>
                )}
                {actions}
              </Box>

              {paginated && (
                <TablePagination
                  component="div"
                  labelRowsPerPage="Įrašų puslapyje"
                  labelDisplayedRows={displayedRows}
                  count={count ?? 0}
                  page={page}
                  rowsPerPage={size}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowPerPageChange}
                />
              )}
            </Box>

            <Divider />

            {showSelectAllPrompt && (
              <>
                <Alert severity="info" icon={false}>
                  {selecting ? (
                    'Palaukite...'
                  ) : (
                    <Link onClick={handleSelectAll}>
                      Žymėti visus {count} rezultatus
                    </Link>
                  )}
                </Alert>

                <Divider />
              </>
            )}
          </Box>
        )}

        <MuiTable size="small" {...rest}>
          <TableHead>{head}</TableHead>

          <TableBody>
            {hasRows ? (
              children
            ) : (
              <TableEmpty
                headSize={(head as any)?.props?.children?.length}
                noRecordsText={noRecordsText}
                noRecordsIcon={noRecordsIcon}
              />
            )}
          </TableBody>

          {footer && <TableFooter>{footer}</TableFooter>}
        </MuiTable>
      </TableContainer>
    </Paper>
  );
};

export default Table;
