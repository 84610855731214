import { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import EuroPrice from 'components/EuroPrice/EuroPrice';
import ConfirmableIconButton from 'components/ConfirmableIconButton';

import { formatDateTime } from 'lib';
import { useDeleteInvoicePayment } from 'features/Invoice';
import { useDialog } from 'components/Dialogs';
import { ClubInvoiceView, ClubInvoiceViewPayment, PaymentMethod } from 'schema';
import CopyableText from 'components/CopyableText';

type Props = {
  invoice: ClubInvoiceView;
  payment: ClubInvoiceViewPayment;
};

const typeLookup: Record<PaymentMethod, string> = {
  neopay: 'Neopay',
  cash: 'Rankinis',
};

const InvoicePaymentsPayment = ({ invoice, payment }: Props) => {
  const [, { open }] = useDialog('paymentEdit');
  const remove = useDeleteInvoicePayment(invoice.id, payment.id);

  const handleOpenClick = useCallback(() => {
    open({ invoice, payment });
  }, [open, invoice, payment]);

  return (
    <TableRow hover sx={{ cursor: 'pointer' }} onClick={handleOpenClick}>
      <TableCell>{formatDateTime(payment.paidAt)}</TableCell>
      <TableCell align="center">{typeLookup[payment.method]}</TableCell>

      <TableCell align="center">
        {<EuroPrice>{payment.amount}</EuroPrice>}
      </TableCell>

      <TableCell>
        {payment.method === 'neopay' && (
          <CopyableText>{payment.transactionId}</CopyableText>
        )}
      </TableCell>

      <TableCell>{payment.comment}</TableCell>

      <TableCell align="right">
        {payment.canDelete && (
          <ConfirmableIconButton
            onConfirm={remove.mutate}
            size="small"
            text={`Ar tikrai norite ištrinti šį mokėjimą?`}>
            <DeleteIcon fontSize="small" color="error" />
          </ConfirmableIconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default InvoicePaymentsPayment;
