import { useCallback } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import FormActions from 'components/FormActions';
import InvoicePaymentsPayment from 'components/InvoicePaymentsPayment';
import Section, { SectionProps } from 'components/Section';

import { useDialog } from 'components/Dialogs';
import { ClubInvoiceView } from 'schema';

type Props = SectionProps & {
  invoice: ClubInvoiceView;
};

const InvoicePayments = ({ invoice, ...props }: Props) => {
  const [, { open }] = useDialog('paymentAdd');

  const handleAddClick = useCallback(() => {
    open({ invoice });
  }, [invoice, open]);

  return (
    <Section title="Mokėjimai" {...props}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th">Data</TableCell>

            <TableCell component="th" align="center">
              Būdas
            </TableCell>
            
            <TableCell component="th" align="center">
              Suma
            </TableCell>

            <TableCell component="th">Transakcijos ID</TableCell>
            <TableCell component="th">Pastabos</TableCell>
            <TableCell component="th" />
          </TableRow>
        </TableHead>
        {invoice.payments.map(payment => (
          <InvoicePaymentsPayment invoice={invoice} payment={payment} />
        ))}
      </Table>

      <FormActions sx={{ mt: 2 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleAddClick}>
          Pridėti
        </Button>
      </FormActions>
    </Section>
  );
};

export default InvoicePayments;
