import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useClub } from 'components/ClubProvider';
import { useNotification } from 'components/Notifications';
import { useDownload, useErrorHandler } from 'lib';

import {
  ApiResponse,
  ClubInvoiceIndexInput,
  ClubInvoiceIndexParams,
  ClubInvoiceListView,
  ClubInvoiceShowInput,
  ClubInvoiceShowSyncInput,
  ClubInvoiceSummaryInput,
  ClubInvoicesSendToAccounting,
  ClubInvoicesAddDiscount,
  InvoiceCorrectionForm,
  InvoiceDiscountForm,
  InvoiceItemForm,
  ClubInvoiceView,
  Entity,
  clubInvoiceAddDiscount,
  clubInvoiceDelete,
  clubInvoiceGenerateMonthly,
  clubInvoiceIndex,
  clubInvoiceItemDelete,
  clubInvoiceItemUpdate,
  clubInvoiceRetrySync,
  clubInvoiceSend,
  clubInvoiceSendPending,
  clubInvoiceSendToAccounting,
  clubInvoiceAddBulkDiscount,
  clubInvoiceShow,
  clubInvoiceShowSync,
  clubInvoiceSummary,
  clubInvoiceUpdate,
  clubInvoiceUpdateAndSend,
  ClubInvoicesListMeta,
  clubInvoicePaymentCreate,
  ClubInvoicePaymentShowInput,
  clubInvoicePaymentShow,
  clubInvoicePaymentUpdate,
  InvoicePayment,
  clubInvoicePaymentDelete,
  ClubInvoicesBulkSend,
  clubInvoiceBulkSend,
  clubInvoiceBulkReSend,
  ClubInvoiceIdsInput,
  ClubInvoiceIdsParams,
  clubInvoiceIds,
} from 'schema';

export const invoicesKeys = {
  all: [{ scope: 'invoices' }] as const,

  lists: () => [{ ...invoicesKeys.all[0], entity: 'lists' }] as const,
  list: (params: ClubInvoiceIndexInput) =>
    [{ ...invoicesKeys.lists()[0], params }] as const,

  idLists: () => [{ ...invoicesKeys.all[0], entity: 'ids' }] as const,
  ids: (params: ClubInvoiceIdsInput) =>
    [{ ...invoicesKeys.idLists()[0], params }] as const,

  details: () => [{ ...invoicesKeys.all[0], entity: 'details' }] as const,
  detail: (params: ClubInvoiceShowInput) =>
    [{ ...invoicesKeys.details()[0], params }] as const,

  summaries: () => [{ ...invoicesKeys.all[0], entity: 'summaries' }] as const,
  summary: (params: ClubInvoiceSummaryInput) =>
    [{ ...invoicesKeys.summaries()[0], params }] as const,

  syncs: () => [{ ...invoicesKeys.all[0], entity: 'syncs' }] as const,
  sync: (params: ClubInvoiceShowSyncInput) =>
    [{ ...invoicesKeys.syncs()[0], params }] as const,

  paymentDetails: () =>
    [{ ...invoicesKeys.all[0], entity: 'payment-details' }] as const,
  paymentDetail: (params: ClubInvoicePaymentShowInput) =>
    [{ ...invoicesKeys.details()[0], params }] as const,
};

type InvoiceDetailsContext = QueryFunctionContext<
  ReturnType<(typeof invoicesKeys)['detail']>
>;

type InvoicesContext = QueryFunctionContext<
  ReturnType<(typeof invoicesKeys)['list']>
>;

type InvoiceIdsContext = QueryFunctionContext<
  ReturnType<(typeof invoicesKeys)['ids']>
>;

type InvoicesSummaryContext = QueryFunctionContext<
  ReturnType<(typeof invoicesKeys)['summary']>
>;

type InvoicesSyncContext = QueryFunctionContext<
  ReturnType<(typeof invoicesKeys)['sync']>
>;

type InvoicesPaymentDetailsContext = QueryFunctionContext<
  ReturnType<(typeof invoicesKeys)['paymentDetail']>
>;

export function useInvoices(params: ClubInvoiceIndexParams = {}) {
  return useQuery({
    queryKey: invoicesKeys.list({ club: useClub().id, params }),
    queryFn: async ({ queryKey: [{ params }] }: InvoicesContext) =>
      await clubInvoiceIndex(params),
  });
}

export function useInvoice(invoice: number) {
  return useQuery({
    queryKey: invoicesKeys.detail({ club: useClub().id, invoice }),
    queryFn: async ({ queryKey: [{ params }] }: InvoiceDetailsContext) =>
      await clubInvoiceShow(params),
  });
}

export function useInvoicesSummary() {
  return useQuery({
    queryKey: invoicesKeys.summary({ club: useClub().id }),
    queryFn: async ({ queryKey: [{ params }] }: InvoicesSummaryContext) =>
      await clubInvoiceSummary(params),
  });
}

export const useInvoiceSync = (invoice: number) =>
  useQuery({
    queryKey: invoicesKeys.sync({ club: useClub().id, invoice }),
    queryFn: async ({ queryKey: [{ params }] }: InvoicesSyncContext) =>
      await clubInvoiceShowSync(params),
  });

export const useInvoicePaymentDetails = (invoice: number, payment: number) =>
  useQuery({
    queryKey: invoicesKeys.paymentDetail({
      club: useClub().id,
      invoice,
      payment,
    }),
    queryFn: async ({
      queryKey: [{ params }],
    }: InvoicesPaymentDetailsContext) => await clubInvoicePaymentShow(params),
  });

export function useUpdateInvoice(invoice: number) {
  const queryClient = useQueryClient();
  const club = useClub().id;
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: InvoiceCorrectionForm) =>
      clubInvoiceUpdate({ club, invoice, form }),

    onSuccess: data => {
      queryClient.setQueryData(invoicesKeys.detail({ club, invoice }), data);
      queryClient.invalidateQueries({ queryKey: invoicesKeys.lists() });

      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
}

export function useDeleteInvoice(
  invoice: number,
  params: ClubInvoiceIndexParams
) {
  const club = useClub().id;
  const client = useQueryClient();
  const queryKey = invoicesKeys.list({ club, params });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => clubInvoiceDelete({ club, invoice }),
    onMutate: async () => {
      await client.cancelQueries({ queryKey });

      const snapshot =
        client.getQueryData<
          ApiResponse<ClubInvoiceListView[], ClubInvoicesListMeta>
        >(queryKey);

      client.setQueryData<
        ApiResponse<ClubInvoiceListView[], ClubInvoicesListMeta>
      >(queryKey, response =>
        response
          ? {
              ...response,
              data: response.data.filter(item => item.id !== invoice),
            }
          : response
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });

      client.invalidateQueries({
        queryKey: invoicesKeys.summary({ club }),
      });
    },
  });
}

export function useSendInvoice(invoice: number) {
  const queryClient = useQueryClient();
  const queryKey = invoicesKeys.lists();
  const club = useClub().id;
  const { pop } = useNotification();

  return useMutation({
    mutationFn: () => clubInvoiceSend({ club, invoice }),
    onError: useErrorHandler(),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },

    onSuccess: ({ id, sentAt }) => {
      // TODO: @Should update actually displayed set of items
      queryClient.setQueryData<
        ApiResponse<ClubInvoiceListView[], ClubInvoicesListMeta>
      >(queryKey, current =>
        current
          ? {
              ...current,
              data: current.data.map(item =>
                item.id === id ? { ...item, sentAt } : item
              ),
            }
          : current
      );
      pop('Sąskaita išsiųsta');
    },
  });
}

export function useUpdateSendInvoice(invoice: number) {
  const queryClient = useQueryClient();
  const club = useClub().id;
  const queryKey = invoicesKeys.detail({ club, invoice });
  const { pop } = useNotification();

  return useMutation({
    onError: useErrorHandler(),
    mutationFn: (form: InvoiceCorrectionForm) =>
      clubInvoiceUpdateAndSend({ club, invoice, form }),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },

    onSuccess: data => {
      queryClient.setQueryData(queryKey, data);
      pop('Sąskaita išsaugota ir išsiųsta');
    },
  });
}

export function useSendPendingInvoices() {
  const queryClient = useQueryClient();
  const club = useClub().id;
  const { pop } = useNotification();

  return useMutation({
    onError: useErrorHandler(),
    mutationFn: () => clubInvoiceSendPending({ club }),

    onSuccess: invoices => {
      queryClient.invalidateQueries({ queryKey: invoicesKeys.lists() });

      queryClient.invalidateQueries({
        queryKey: invoicesKeys.summary({ club }),
      });

      invoices?.forEach(invoice => {
        queryClient.invalidateQueries({
          queryKey: invoicesKeys.detail({ club, invoice: invoice.id }),
        });
      });

      pop('Visos sąskaitos išsiųstos');
    },
  });
}

export function useRetrySync(invoice: number) {
  const client = useQueryClient();
  const club = useClub();
  const queryKey = invoicesKeys.sync({ club: club.id, invoice });

  return useMutation({
    mutationFn: () => clubInvoiceRetrySync({ club: club.id, invoice }),
    onError: useErrorHandler(),

    onSuccess: sync => {
      client.setQueryData(queryKey, sync);
      client.invalidateQueries({ queryKey });

      client.invalidateQueries({
        queryKey: invoicesKeys.lists(),
      });

      client.invalidateQueries({
        queryKey: invoicesKeys.detail({ club: club.id, invoice }),
      });
    },
  });
}

// TODO: @Hardcode
export const useDownloadInvoice = ({ id }: Entity) =>
  useDownload(`/api/v1/invoices/${id}/pdf`);

export function useUpdateInvoiceItem(invoice: number, item: number) {
  const club = useClub().id;
  const client = useQueryClient();
  const queryKey = invoicesKeys.detail({ club, invoice });

  return useMutation({
    mutationFn: (form: InvoiceItemForm) =>
      clubInvoiceItemUpdate({ club, invoice, item, form }),
    onError: useErrorHandler(),

    onSuccess: data => {
      client.setQueryData<ClubInvoiceView>(
        queryKey,
        current =>
          current && {
            ...current,
            items: current.items.map(i => (i.id === item ? data : i)),
          }
      );
    },
  });
}

export function useAddInvoiceDiscount(invoice: number) {
  const club = useClub().id;
  const client = useQueryClient();
  const queryKey = invoicesKeys.detail({ club, invoice });

  return useMutation({
    mutationFn: (form: InvoiceDiscountForm) =>
      clubInvoiceAddDiscount({ club, invoice, form }),

    onError: useErrorHandler(),

    onSuccess: invoice => {
      client.setQueryData(queryKey, invoice);
      client.invalidateQueries({ queryKey });
    },
  });
}

export function useDeleteInvoiceItem(invoice: number, item: number) {
  const club = useClub().id;
  const client = useQueryClient();
  const queryKey = invoicesKeys.detail({ club, invoice });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => clubInvoiceItemDelete({ club, invoice, item }),

    onMutate: async () => {
      await client.cancelQueries({ queryKey });

      const snapshot = client.getQueryData<ClubInvoiceView>(queryKey);

      client.setQueryData<ClubInvoiceView>(
        queryKey,
        data =>
          data && {
            ...data,
            items: data.items.filter(i => i.id !== item),
          }
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });
    },
  });
}

export function useGenerateMonthly() {
  const club = useClub().id;
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: () => clubInvoiceGenerateMonthly({ club }),

    onSuccess: ({ count }) => {
      pop(`Sugeneruota sąskaitų: ${count}`);

      client.invalidateQueries({
        queryKey: invoicesKeys.lists(),
      });

      client.invalidateQueries({
        queryKey: invoicesKeys.summary({ club }),
      });
    },
  });
}

export function useSetInvoicesDiscount() {
  const club = useClub().id;
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: ClubInvoicesAddDiscount) =>
      clubInvoiceAddBulkDiscount({ club, form }),

    onError: useErrorHandler(),

    onSuccess: () => {
      pop('Nuolaida nustatyta');

      client.invalidateQueries({ queryKey: invoicesKeys.lists() });
    },
  });
}

export function useResetAccountingSync() {
  const club = useClub().id;
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: ClubInvoicesSendToAccounting) =>
      clubInvoiceSendToAccounting({ club, form }),

    onError: useErrorHandler(),

    onSuccess: ({ reset, skipped }) => {
      pop(`Nustatyta ${reset}, praleista: ${skipped}`);

      client.invalidateQueries({ queryKey: invoicesKeys.lists() });
    },
  });
}

export function useAddInvoicePayment(invoice: number) {
  const club = useClub().id;
  const client = useQueryClient();

  return useMutation({
    mutationFn: (form: InvoicePayment) =>
      clubInvoicePaymentCreate({ club, invoice, form }),

    onError: useErrorHandler(),

    onSuccess: () => {
      client.invalidateQueries({
        queryKey: invoicesKeys.detail({ club, invoice }),
      });
    },
  });
}

export function useUpdateInvoicePayment(invoice: number, payment: number) {
  const club = useClub().id;
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: InvoicePayment) =>
      clubInvoicePaymentUpdate({ club, invoice, payment, form }),

    onError: useErrorHandler(),

    onSuccess: () => {
      client.invalidateQueries({
        queryKey: invoicesKeys.detail({ club, invoice }),
      });

      client.invalidateQueries({
        queryKey: invoicesKeys.paymentDetail({ club, invoice, payment }),
      });

      pop('Mokėjimas išsaugotas');
    },
  });
}

export function useDeleteInvoicePayment(invoice: number, payment: number) {
  const club = useClub().id;
  const client = useQueryClient();
  const queryKey = invoicesKeys.detail({ club, invoice });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => clubInvoicePaymentDelete({ club, invoice, payment }),

    onMutate: async () => {
      await client.cancelQueries({ queryKey });

      const snapshot = client.getQueryData<ClubInvoiceView>(queryKey);

      client.setQueryData<ClubInvoiceView>(
        queryKey,
        data =>
          data && {
            ...data,
            payments: data.payments.filter(i => i.id !== payment),
          }
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });
    },
  });
}

export function useBulkSend() {
  const club = useClub().id;
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: ClubInvoicesBulkSend) =>
      clubInvoiceBulkSend({ club, form }),

    onError: useErrorHandler(),

    onSuccess: ({ sent, skipped }) => {
      pop(`Išsiųsta ${sent}, praleista: ${skipped}`);

      client.invalidateQueries({ queryKey: invoicesKeys.lists() });
      client.invalidateQueries({ queryKey: invoicesKeys.summaries() });
    },
  });
}

export function useBulkReSend() {
  const club = useClub().id;
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: ClubInvoicesBulkSend) =>
      clubInvoiceBulkReSend({ club, form }),

    onError: useErrorHandler(),

    onSuccess: ({ sent, skipped }) => {
      pop(`Išsiųsta ${sent}, praleista: ${skipped}`);
    },
  });
}

type UseInvoiceIds = [() => Promise<Entity[]>, boolean];

export function useInvoiceIds(params: ClubInvoiceIdsParams): UseInvoiceIds {
  const club = useClub().id;
  const client = useQueryClient();
  const queryKey = invoicesKeys.ids({ club, params });

  const query = useQuery({
    queryKey,
    queryFn: async ({ queryKey: [{ params }] }: InvoiceIdsContext) =>
      await clubInvoiceIds(params),
  });

  return [() => client.fetchQuery({ queryKey }), query.isFetching];
}
