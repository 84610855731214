import { useCallback, MouseEvent } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box, { BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { useNotification } from 'components/Notifications';

type Props = BoxProps;

const CopyableText = ({ onClick, children, sx = [], ...props }: Props) => {
  const { pop } = useNotification();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();

      navigator.clipboard.writeText(event.currentTarget.innerText);
      pop('Nukopijuota');

      onClick && onClick(event);
    },
    [onClick, pop]
  );

  return (
    <Box
      onClick={handleClick}
      sx={[
        { display: 'flex', alignItems: 'center' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}>
      <Box>{children}</Box>

      <IconButton size="small" sx={{ ml: 1 }}>
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default CopyableText;

